import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31048875"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "list-item"
}
const _hoisted_2 = { class: "category-label" }
const _hoisted_3 = { class: "list-item-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImgCircle = _resolveComponent("BaseImgCircle")!
  const _component_TalkroomListItemNickname = _resolveComponent("TalkroomListItemNickname")!
  const _component_TalkroomListItemTimestamp = _resolveComponent("TalkroomListItemTimestamp")!
  const _component_TalkroomListItemContent = _resolveComponent("TalkroomListItemContent")!
  const _component_TalkroomListItemCommentCount = _resolveComponent("TalkroomListItemCommentCount")!
  const _component_router_link = _resolveComponent("router-link")!

  return (
      _ctx.talkroom.category != '문의 및 오류 신고' &&
      _ctx.talkroom.category != '이 기능 추가해주세요!'
    )
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: '/talkroom/' + _ctx.talkroom.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_ctx.talkroom.category), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BaseImgCircle, {
                size: "40px",
                src: 
            _ctx.talkroom.thumbnail ?? require('@/assets/images/account_default.png')
          
              }, null, 8, ["src"]),
              _createElementVNode("article", null, [
                _createVNode(_component_TalkroomListItemNickname, {
                  title: _ctx.talkroom.nickname
                }, null, 8, ["title"]),
                _createVNode(_component_TalkroomListItemTimestamp, {
                  tstamp: _ctx.talkroom.tstamp
                }, null, 8, ["tstamp"]),
                _createVNode(_component_TalkroomListItemContent, {
                  content: _ctx.talkroom.content
                }, null, 8, ["content"]),
                _createVNode(_component_TalkroomListItemCommentCount, {
                  count: _ctx.talkroom.commentCount
                }, null, 8, ["count"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["to"])
      ]))
    : _createCommentVNode("", true)
}