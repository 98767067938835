
import { defineComponent } from "vue";
import { TalkroomListItemType } from "./talkroom";
import BaseImgCircle from "@/components/BaseImgCircle.vue";
import TalkroomListItemNickname from "./ListItemNickname.vue";
import TalkroomListItemTimestamp from "./ListItemTimestamp.vue";
import TalkroomListItemContent from "./ListItemContent.vue";
import TalkroomListItemCommentCount from "./ListItemCommentCount.vue";

export default defineComponent({
  name: "TalkroomListItem",
  components: {
    BaseImgCircle,
    TalkroomListItemNickname,
    TalkroomListItemTimestamp,
    TalkroomListItemContent,
    TalkroomListItemCommentCount,
  },
  props: {
    talkroom: {
      type: Object as () => TalkroomListItemType,
      required: true,
    },
  },
});
